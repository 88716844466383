import React, { useEffect, useRef } from 'react';

import {
    grid,
    gridSuccess,
    title as titleClass,
    description as descriptionClass,
    formWrapper,
    thankYouBox,
    sectionBox,
} from './password-recovery.module.scss';
import { passwordRecoveryForm } from '../../formik/password-recovery.form';
import { ISection } from '../../models/section.model';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { useUser } from '../../hooks/use-user';

import Section from '../hoc/section';
import FormGenerator from '../molecules/form-generator';
import ThankYouBox from '../molecules/thank-you-box';

export interface IPasswordRecoveryProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

export default function PasswordRecovery({ className, TitleTag, section }: IPasswordRecoveryProps) {
    const sectionRef = useRef<HTMLElement>(null);
    const { sectionId, css, style } = section;
    const [title, description, successTitle, successDescription] = section.content.texts;
    const [successButton] = section.content.buttons;
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(
        userState.recoverPassword.errors
    );

    useEffect(() => {
        if (!sectionRef.current) return;
        if (userState.recoverPassword.isSuccess) {
            sectionRef.current.scrollIntoView();
        }
    }, [userState.recoverPassword.isSuccess]);

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionBox}`}
            classes={{
                container: `${grid} ${userState.recoverPassword.isSuccess ? gridSuccess : ''}`,
                title: titleClass,
                description: descriptionClass,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
            title={title}
            description={description}
            ref={sectionRef}
        >
            {!userState.recoverPassword.isSuccess ? (
                <FormGenerator
                    formRef={formikRef}
                    className={formWrapper}
                    formikProps={{ onSubmit: (values) => userState.recoverPassword.fetch(values) }}
                    name="PasswordRecovery"
                    fields={passwordRecoveryForm}
                    errorMessage={globalErrorMessage}
                    submitLoading={userState.recoverPassword.isLoading}
                />
            ) : (
                <ThankYouBox
                    className={thankYouBox}
                    data={{
                        title: successTitle || '',
                        description: successDescription || '',
                        button: successButton,
                    }}
                />
            )}
        </Section>
    );
}
